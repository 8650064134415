import { render, staticRenderFns } from "./TemplateRequisicao.vue?vue&type=template&id=111f03b2&scoped=true&"
import script from "./TemplateRequisicao.vue?vue&type=script&setup=true&lang=js&"
export * from "./TemplateRequisicao.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./TemplateRequisicao.vue?vue&type=style&index=0&id=111f03b2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "111f03b2",
  null
  
)

export default component.exports