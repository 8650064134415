<template>
    <div>
        <Navbar page="Configuração Plugin" link="/requisicao/gerenciar" nameLink="Gerenciar" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">            
            <div v-if="form" class="grid grid-cols-12 gap-3 my-5">
                <div class="col-span-12">
                    <h2 class="text-lg font-semibold">Configurações Plugin</h2>
                    <p class="text-base font-medium text-blue-800 mt-2">
                        O plugin de requisições é utilizado também para o canal de denuncia. <br/>Funcionando como canal para envio de solicitações de titulares na requisição e canal de denúncias na gestão de incidentes. 
                    </p>
                </div>
                <div class="col-span-12 md:col-span-12">
                    <label class="block text-base font-semibold my-1">Template:</label>
                    <TemplateRequisicao 
                        :corFundo="form.corFundo.hex ? form.corFundo.hex: form.corFundo" 
                        :corSubtitulos="form.corSubtitulos.hex ? form.corSubtitulos.hex: form.corSubtitulos" 
                        :corButton="form.corButton.hex ? form.corButton.hex: form.corButton" 
                        :corButtonConsulta="form.corButtonConsulta.hex ? form.corButtonConsulta.hex: form.corButtonConsulta" 
                        :textInicial="form.textInicial" 
                        :textSequencia="form.textSequencia" 
                        :textDenuncia="form.textDenuncia" 
                        :logo="form.logo" 
                        class="my-2"
                    />
                </div>
            </div>
            <div v-if="form" class="grid grid-cols-12 gap-4 my-5">
                <div class="col-span-12 md:col-span-12">
                    <h2 class="text-lg font-semibold">Logo para template</h2>
                </div>
                <div class="col-span-12 ">
                    <button 
                        type="button" 
                        class="border rounded-md px-2 py-1 hover:bg-gray-100" 
                        @click="voltarLogoPadrao"
                    >
                        Voltar a logo padrão
                    </button>
                </div>
                <div class="col-span-12 ">
                    <small class="text-sm font-medium">Tamanho recomendado 400x200, PNG sem fundo</small>
                    <div class="grid grid-cols-12 gap-6 mt-3">
                        <div class="col-span-12 md:col-span-4">
                            <vue-dropzone 
                                ref="doc" 
                                id="doc" 
                                class="w-full" 
                                :options="dropzoneOptions" 
                                v-on:vdropzone-success="updateDoc"
                                v-on:vdropzone-removed-file ="removerLogo"
                            >
                            </vue-dropzone>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <img class="h-16" v-if="this.form.logo && this.form.logo.filename" :src="`${this.url_api}/upload?mimetype=image/jpeg&filename=${this.form.logo.filename}&folder=empresas`"/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="form" class="grid grid-cols-12 gap-4 my-5">
                <div class="col-span-12 md:col-span-12">
                    <h2 class="text-lg font-semibold">Textos Requisição</h2>
                </div>
                <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                    <label for="textInicial" class="block text-sm font-medium mb-2">Texto inicial <small class="text-red-600 ml-1">({{form.textInicial.length}} / 200 caracteres ideais)</small></label>
                    <textarea rows="5" type="text" v-model="form.textInicial" name="textInicial" id="textInicial" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                </div>
                <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                    <label for="textSequencia" class="block text-sm font-medium mb-2">Texto sequência <small class="text-red-600 ml-1">({{form.textSequencia.length}} / 180 caracteres ideais)</small></label>
                    <textarea rows="5" type="text" v-model="form.textSequencia" name="textSequencia" id="textSequencia" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                </div>
                 <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                    <label for="textSequencia" class="block text-sm font-medium mb-2">Texto popup <small class="text-red-600 ml-1">({{form.textPopUp.length}} / 280 caracteres ideais)</small></label>
                    <textarea rows="5" type="text" v-model="form.textPopUp" name="textSequencia" id="textSequencia" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                </div>
                <div class="col-span-12 md:col-span-12">
                    <h2 class="text-lg font-semibold">Texto Canal de denúncias</h2>
                </div>
                <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                    <label for="textDenuncia" class="block text-sm font-medium mb-2">Texto denúncia <small class="text-red-600 ml-1">({{form.textDenuncia.length}} / 230 caracteres ideais)</small></label>
                    <textarea rows="5" type="text" v-model="form.textDenuncia" name="textDenuncia" id="textDenuncia" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                </div>
            </div>
            
            <div v-if="form" class="grid grid-cols-12 gap-4 my-5">
                <div class="col-span-12 md:col-span-12">
                    <h2 class="text-lg font-semibold">Cores Textos</h2>
                </div>
                <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                    <label class="block text-sm font-medium mb-2">Cor fundo</label>
                    <sketch-picker v-model="form.corFundo" />
                </div>
                <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                    <label class="block text-sm font-medium mb-2">Cor Subtítulo e texto (conteúdo) </label>
                    <sketch-picker v-model="form.corSubtitulos" />
                </div>
                <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                    <label class="block text-sm font-medium mb-2">Cor Botão salvar requisção </label>
                    <sketch-picker v-model="form.corButton" />
                </div>
                <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                    <label class="block text-sm font-medium mb-2">Cor Botão consulta protocolo </label>
                    <sketch-picker v-model="form.corButtonConsulta" />
                </div>
            </div>
            <div v-if="form" class="grid grid-cols-12 gap-6 my-5">
                <div class="col-span-12 md:col-span-12 mt-2">
                    <h2 class="text-lg font-semibold">Icone Cookie Banner</h2>
                </div>
                <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                    <label class="block text-sm font-medium mb-2">Cor Icone Cookie</label>
                    <sketch-picker v-model="form.corIcon" />
                </div>
                <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                    <label class="block text-sm font-medium mb-2">Texto Icone Cookie</label>
                    <input type="text" v-model="form.textBanner" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full h-9 shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                </div>
             </div>
            <div class="grid grid-cols-2 mt-5">
                <div class="py-3 text-left">
                    <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="py-3 text-right">
                    <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chrome } from 'vue-color';
import TemplateRequisicao from '@/components/TemplateRequisicao.vue';
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
    components: {
        vueDropzone,
        TemplateRequisicao,
        'sketch-picker': Chrome,
    },
    data() {
        return {
            route: 'requisicao',
            form: {
                corFundo: {
                    hex: "#0097B2",
                },
                corButton: {
                    hex: "#E8AF1B",
                },
                corSubtitulos: {
                    hex: "#FFFFFF",
                },
                corButtonConsulta: {
                    hex: "#8a8888",
                },
                corIcon: {
                    hex: "#E8AF1B",
                },
                textInicial: "A Lei Geral de Proteção de Dados garante a você uma série de direitos sobre suas informações pessoais.<br/>Clique #AquiDireitos e conheça quais são.",
                textBanner: "Seus Direitos",
                textSequencia: "Caso deseja requerer junto à empresa algum destes direitos, <b>basta preencher o formulário abaixo.</b>",
                textPopUp: "Este é um canal exclusivo para que você exerça seus direitos sobre a Lei Geral de Proteção de Dados Pessoais ou registre uma denúncia relacionada a dados pessoais.<br/>Você realmente deseja requerer algum direito ou fazer uma denúncia?",
                textDenuncia: "Este canal de denúncias é destinado à comunicação de situações envolvendo práticas suspeitas relacionadas ao tratamento de dados pessoais. Sua denúncia poderá ser anônima.",
                logo: '',
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste sua logo aqui',
                dictRemoveFile: 'Remover',
                acceptedFiles: 'image/*'
            }),
        }
    },
    methods: {
        async save() {

            if(!this.form.logo?.filename) return this.$vToastify.warning("Adicione uma logo ao template.");

            this.form.corFundo = this.form.corFundo.hex ? this.form.corFundo.hex: this.form.corFundo;
            this.form.corButton = this.form.corButton.hex ? this.form.corButton.hex: this.form.corButton;
            this.form.corSubtitulos = this.form.corSubtitulos.hex ? this.form.corSubtitulos.hex: this.form.corSubtitulos;
            this.form.corButtonConsulta = this.form.corButtonConsulta.hex ? this.form.corButtonConsulta.hex: this.form.corButtonConsulta;
            this.form.corIcon = this.form.corIcon.hex ? this.form.corIcon.hex: this.form.corIcon;

            const req = await this.$http.post(`/v1/${this.route}/editConfigRequisicao`, this.form);
            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async updateDoc(file, response) {
            this.form.logo = response.file;
        },
        removerLogo(){
            this.form.logo = '';
            this.voltarLogoPadrao();
        },
        voltarLogoPadrao(){
            if (this.$refs.doc.getAcceptedFiles().length) {
                this.$refs.doc.removeAllFiles(); // Remove todos os arquivos do dropzone
            }
            this.form.logo = this.$store.state?.empresa?.logo || '';
        }
    },
    async beforeMount() {
        const id = this.$route.params.id;
        this.form._id = id;

        await this.$http.post(`/v1/${this.route}/configRequisicao`, this.form)
        .then((resp)=> {
            if(resp.data.item) Object.assign(this.form, resp.data.item)
         })
        .catch((e)=>{
            const msg = 'Erro ao pegar configuração de requisção.'
            console.log(msg, e)
            this.$vToastify.error(msg);
        })
        if(!this.form?.logo?.filename){
            this.voltarLogoPadrao();
        }
    },
}
</script>